<template>
	<div class="main-content">
		<page-header :title="`${date ? 'Waitlist for ' + date : ''}`" pre-title="Overview">
			<template slot="actions">
				<b-dropdown variant="primary" class="sh-dropdown" :no-caret="true" right v-if="selectedFilterData">
					<template #button-content>
						<span>Action</span>
						<i class="fe fe-chevron-down ml-2"></i>
					</template>
					<b-dropdown-item @click.prevent="addVehicleToItinerary()">
						<span class="action-item">Add Vehicle</span>
					</b-dropdown-item>
					<b-dropdown-item @click.prevent="showChangeVehicle = true">
						<span class="action-item">Change Vehicle</span>
					</b-dropdown-item>
					<b-dropdown-item v-if="selectedFilterData.availableSeats > 0" @click.prevent="notifyAllUsers()">
						<span class="action-item">Notify all</span>
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</page-header>

		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between pl-0">
							<div class="col">
								<div class="input-group input-group-flush d-flex flex-row-reverse">
									<input @keyup="applyFilter" v-model.trim="search" class="form-control list-search" type="search"
										placeholder="Search" />
									<span class="input-group-text border-0">
										<i class="fe fe-search"></i>
									</span>
								</div>
							</div>
							<div class="col-auto d-flex align-items-end px-0">
								<download-button v-if="tableData.length" :downloading="downloadingReport" label="Download Report"
									@download="downloadReport()">
								</download-button>
							</div>
						</div>
						<div class="col px-0 d-flex">
							<div class="col"></div>
							<div class="col-auto">
								<filter-button :options="waitlistRoutes" :loading-text="'Loading routes'"
									:loading="loadingFilters && !waitlistRoutes.length">
									<template #label>
										<span class="text-grey">Filter by route:</span> {{ filter.route.routeCode }}
									</template>

									<template #option="data">
										<span class="w-100 d-inline-block" @click="selectRouteFilter(data)">
											{{ data.routeCode }}
										</span>
									</template>
								</filter-button>
							</div>
						</div>
						<div class="table-filter-row" v-if="routeSelected">
							<div class="row">
                &nbsp;
                &nbsp;
								<div class="col">
									<span class="font-weight-bold">{{ filter.route.routeCode }}</span>
								</div>
                &nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

								<div class="" v-if="selectedFilterData">
									<span class="font-weight-bold">{{ selectedFilterData.availableSeats }} seats left</span>
								</div>
							</div>
							<div class="col-auto">
								<div>
									<span @click="selectRouteItinerary(itinerary)" class="itinerary-pill"
										:class="{ 'itinerary-pill--active': filter.itinerary === itinerary }"
										v-for="itinerary in filter.route.itineraries" :key="itinerary">
										{{ itinerary }}
									</span>
								</div>
							</div>
						</div>
						<b-table responsive striped :items="tableData" :fields="fields" :busy="loading"
							empty-text="No records available" show-empty>
							<template #table-busy>
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-3"><strong>Loading...</strong></p>
								</div>
							</template>
							<template #empty="data">
								<p class="text-center">{{ data.emptyText }}</p>
							</template>

							<template #cell(route)="data">
								<span>{{ data.item.routeCode }}</span>
							</template>

							<template #cell(itinerary)="data">
								<span>{{ data.item.itenery }}</span>
							</template>

							<template #cell(name)="data">

								<div class="d-flex align-items-center">
									<user-avatar :user="{ fname: data.item.fname, lname: data.item.lname, avatar: data.item.avatar }"
										size="sm">
									</user-avatar>
									<router-link :to="{ name: 'ShowUser', params: { userId: data.item.userId } }" class="ml-2">
										{{ `${data.item.fname} ${data.item.lname}` }}
									</router-link>
								</div>

							</template>

							<template #cell(phone)="data">
								<span>{{ data.item.phone }}</span>
							</template>

							<template #cell(email)="data">
								<span>{{ data.item.email }}</span>
							</template>

							<template #cell(busStop)="data">
								<span>{{ data.item.pickup }}</span>
							</template>

							<template #cell(actions)="data">
								<span>{{ data.item.count }}</span>
							</template>

							<template #cell(routes)="data">
								<span>{{ data.item.routes }}</span>
							</template>

							<template #cell(actions)="data">
								<button class="btn btn-sm btn-notify" :class="{ 'btn-notify--reserved': data.item.status !== 'pending' }"
									@click="notifyUser(data.item)" :disabled="disableNotify(data.item.status)">
									{{ data.item.status === 'pending' ? 'Notify' : data.item.status === 'cancelled' ? 'Canceled' : 'Booked'
									}}
								</button>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords && !loading">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
								</div>
								<div class="col-auto">
									<b-pagination @change="fetchWaitList()" v-model="currentPage" :total-rows="totalRecords"
										:per-page="pageSize"></b-pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<change-waitlist-vehicle @close="showChangeVehicle = false" @vehicle-updated="fetchWaitList()"
			v-if="selectedFilterData" v-bind="selectedFilterData" :show="showChangeVehicle">
		</change-waitlist-vehicle>
	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'
import TableView from '@/mixins/tableview'
import UserAvatar from '@/components/core/UserAvatar'
import { extractErrorMessage } from '@/utils/helpers'
import Swal from 'sweetalert2'
import filters from '@/filters'
import DownloadButton from '@/components/core/DownloadButton'
import FilterButton from '@/components/core/FilterButton'
import ChangeWaitlistVehicle from '@/views/Waitlist/components/ChangeWaitlistVehicle'
import { ExportToCsv } from 'export-to-csv'

export default {
  name: 'WaitListDetail',
  components: { ChangeWaitlistVehicle, FilterButton, DownloadButton, UserAvatar, PageHeader },
  mixins: [TableView],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      tableData: [],
      search: '',
      filter: {
        route: {
          routeCode: 'All',
          itineraries: null
        },
        itinerary: null
      },
      fields: [
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'itinerary',
          label: 'Time'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'phone',
          label: 'Phone NUmber'
        },
        {
          key: 'email',
          label: 'Email Address'
        },
        {
          key: 'busStop',
          label: 'Bus Stop'
        }
      ],
      downloadingReport: false,
      selectedFilterData: null,
      date: null,
      loadingFilters: false,
      waitlistRoutes: [],
      showChangeVehicle: false
    }
  },
  computed: {
    routeSelected() {
      return this.filter.route.routeCode && this.filter.route.routeCode !== 'All'
    }
  },
  created() {
    if (!this.$route.query.date) {
      this.$router.push({ name: 'WaitListSummary' })
    } else {
      this.date = filters.date(this.$route.query.date, 'MMMM d, yyyy')
      this.fetchWaitList(true)
      this.fetchWaitlistRoutes()
    }
  },
  methods: {
    disableNotify(status) {
      if (this.selectedFilterData) {
        return this.selectedFilterData.availableSeats <= 0 || status !== 'pending'
      } else {
        return status !== 'pending'
      }
    },
    async fetchWaitList(refreshUI = false) {
      if (refreshUI) {
        this.selectedFilterData = null
        this.currentPage = 1
        this.loading = true
      }
      try {
        const date = this.$route.query.date
        let url = `/waitlist/date/${date}`
        switch (true) {
          case this.filter.itinerary && this.filter.route.routeCode && this.filter.route.routeCode !== 'All':
            url = `/waitlist/itenery/${this.filter.route.routeCode}/${date}?itenery=${this.filter.itinerary}`
            this.fields = [...this.fields, { key: 'actions', label: 'Action' }]
            break
          case this.filter.route?.routeCode && this.filter.route.routeCode !== 'All':
            url += `?routeCode=${this.filter.route.routeCode}`
            break
        }
        const response = await this.axios.get(url, {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            ...(this.search ? { search: this.search } : {})
          }
        })
        this.tableData = response.data.data
        this.totalRecords = response.data.metadata.count
        if (this.filter.itinerary && this.filter.route && response.data.routeDetails && this.tableData?.length) {
          this.selectedFilterData = response.data.routeDetails
          this.selectedFilterData.routeDetailsId = this.tableData[0].routeDetailsId
          this.selectedFilterData.routeCode = this.tableData[0].routeCode
          this.selectedFilterData.itineraryId = this.selectedFilterData.iteneryId
          this.selectedFilterData.itinerary = this.selectedFilterData.itenery
        }
      } catch (e) {
        (e)
      } finally {
        this.loading = false
      }
    },
    async downloadReport() {
      this.downloadingReport = true
      const date = this.$route.query.date
      try {
        let url = `/waitlist/date/${date}`
        switch (true) {
          case this.filter.itinerary && this.filter.route.routeCode && this.filter.route.routeCode !== 'All':
            url = `/waitlist/itenery/${this.filter.route.routeCode}/${date}?itenery=${this.filter.itinerary}`
            break
          case this.filter.route?.routeCode && this.filter.route.routeCode !== 'All':
            url += `?routeCode=${this.filter.route.routeCode}`
            break
        }
        const waitlistData = await this.axios.get(url).then((res) => res.data?.data || [])
        const csvData = waitlistData.map((x) => {
          return {
            routeCode: x.routeCode,
            itinerary: x.itenery,
            name: `${x.fname} ${x.lname}`,
            phone: x.phone || 'N/A',
            email: x.email || 'N/A',
            pickup: x.pickup
          }
        })
        const docName = `Waitlist for ${this.date}`
        const csvParams = {
          filename: docName,
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: docName,
          useTextFile: false,
          useBom: true,
          headers: [
            'Route',
            'Time',
            'Name',
            'Phone',
            'Email Address',
            'Bus stop'
          ]
        }
        const csvExporter = new ExportToCsv(csvParams)
        csvExporter.generateCsv(csvData)
        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (e) {
        this.$swal({
          icon: 'error',
          title: 'An error occurred!',
          text: extractErrorMessage(e, 'Failed to download report'),
          showCloseButton: true
        })
      } finally {
        this.downloadingReport = false
      }
    },
    async fetchWaitlistRoutes() {
      this.loadingRoutes = true
      try {
        const routes = await this.axios.get(`/waitlist/filter/routes/${this.$route.query.date}`)
          .then((res) => res.data?.data)
        for (let i = 0; i < routes.length; i++) {
          const itineraries = await this.axios.get(`/waitlist/filter/itenery/${this.$route.query.date}/${routes[i]}`)
            .then((res) => res.data.data)
          this.waitlistRoutes.push({ routeCode: routes[i], itineraries })
        }
        this.waitlistRoutes.unshift({ routeCode: 'All', itineraries: [] })
      } catch (e) {
        (e)
      } finally {
        this.loadingRoutes = false
      }
    },
    applyFilter(event) {
      if ((event.key === 'Enter' || event.keyCode === 13) && this.search) {
        this.fetchWaitList(true)
      } else if (!this.search) {
        this.fetchWaitList(false)
      }
    },
    showActionSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Success',
        text: 'Action successful!',
        showCloseButton: true
      })
    },
    notifyAllUsers() {
      this.$swal({
        icon: 'question',
        title: 'Notify users of seat availability',
        text: 'Proceed to notify users of seat availability',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .get(`/waitlist/notify/all/${this.selectedFilterData.routeDetailsId}`)
            .then(() => {
              // this.fetchWaitList(true);
            })
            .catch((error) => {
              const msg = extractErrorMessage(error, 'An error occurred. Failed to notify user')
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.showActionSuccess()
          }
        })
    },
    notifyUser(waitListRecord) {
      this.$swal({
        icon: 'question',
        title: 'Notify user of seat availability',
        text: 'Proceed to notify user of seat availability',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .get(`/waitlist/notify/single/${waitListRecord.id}`)
            .then(() => {
              this.fetchWaitList(true)
            })
            .catch((error) => {
              const msg = extractErrorMessage(error, 'An error occurred. Failed to notify user')
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.showActionSuccess()
          }
        })
    },
    addVehicleToItinerary() {
      this.$router.push({
        name: 'ShowRouteItinerary',
        params: {
          routeId: this.selectedFilterData.routeId,
          itineraryId: this.selectedFilterData.iteneryId
        },
        query: {
          addVehicle: true
        }
      })
    },
    selectRouteFilter(data) {
      if (this.filter.route !== data) {
        this.filter.route = data
        this.fetchWaitList(true)
        this.filter.itinerary = null
      }
    },
    selectRouteItinerary(data) {
      if (data === this.filter.itinerary) {
        this.filter.itinerary = null
      } else {
        this.filter.itinerary = data
      }
      this.fetchWaitList(true)
    }
  }

}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/b-table";
@use "src/assets/scss/partials/sh-colors";

.table-filter-row {
  background-color: sh-colors.$sh-gray-9;
  padding: .75rem 1rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  .itinerary-pill {
    padding: 0.25rem 0.75rem;
    gap: 0.625rem;
    border: 1px solid #131516;
    border-radius: 0.375rem;
    cursor: pointer;

    &--active {
      background-color: #131516;
      color: #FFFFFF;
    }
  }
}

.sh-dropdown {
  ::v-deep ul[role=menu] {
    margin-top: .5rem;
    padding: 1rem;

    li[role=presentation]>a {
      border-radius: .5rem;
      width: 100%;
      padding: .75rem;
      transition: background-color, color ease-in-out .2s;

      &:hover {
        background-color: sh-colors.$sh-primary-dark;
        color: #FFFFFF;
      }
    }
  }
}

.text-grey {
  color: sh-colors.$sh-gray-5;
  cursor: pointer;
}

.btn-notify {
  background-color: sh-colors.$sh-green-500;
  color: sh-colors.$sh-primary-dark;
  border-radius: 6px;

  &--reserved {
    background-color: sh-colors.$sh-neutral-400;
    color: #FFFFFF;
  }
}
</style>

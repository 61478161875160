<template>
	<b-modal :id="modalId"
		@close="close"
		size="md"
		no-close-on-backdrop
		no-close-on-esc>
		<template #modal-title>
			{{'Change Vehicle for this route itinerary'}}
		</template>
		<div class="py-2">

			<template v-if="isLoading">
				<div class="d-flex flex-column justify-content-center align-items-center">
					<div class="spinner-border" role="status"></div>
					<p class="text-center mt-3"><strong>Loading...</strong></p>
				</div>
			</template>

			<template v-else>
				<p class="mb-4">You are about to change vehicle for <span class="font-weight-bold">{{routeCode}}, {{itinerary}}</span></p>

				<div class="form-group">
					<label>Select Driver</label>
					<VueSelect
						:multiple="false"
						v-model="tripActiveDriver"
						:custom-label="driverDescription"
						track-by="id"
						:option-height="104"
						:options="itineraryDrivers">
						<template slot="singleLabel" slot-scope="props">
							<div class="media">
								<img
									v-if="props.option.driver && props.option.driver.avatar"
									class="avatar avatar-sm avatar-img rounded-circle option__image mr-2"
									style="width: 40px; height: 40px"
									:src="props.option.driver.avatar"
									:alt="props.option.driver.fname"
									/>
								<div class="option__desc media-body">
									<span class="option__title">{{ props.option.driver.fname }} {{ props.option.driver.lname }}</span>
									<div class="option__small text-small" v-if="props.option.vehicle">
										<small>
											{{ props.option.vehicle.brand }} {{ props.option.vehicle.name }}
											{{ props.option.vehicle.registration_number ? ` • ${props.option.vehicle.registration_number}`: '' }}
											{{ props.option.vehicle.seats ? ` • ${props.option.vehicle.seats} seater`: '' }}
										</small>
									</div>
									<div v-else-if="props.option.driver.fname">
										<small>No vehicle assigned</small>
									</div>
								</div>
							</div>
						</template>
						<template slot="option" slot-scope="props">
							<div class="media">
								<img
									v-if="props.option"
									class="avatar avatar-sm avatar-img rounded-circle option__image mr-2"
									style="width: 40px; height: 40px"
									:src="props.option.avatar || userAvatar"
									:alt="props.option.driver.fname"
									/>
								<div class="option__desc media-body">
									<span v-if="props.option.driver" class="option__title">{{ props.option.driver.fname }} {{ props.option.driver.lname }}</span>
									<div class="option__small text-small" v-if="props.option.vehicle">
										<small style="word-break: break-word">
											{{ props.option.vehicle.brand }} {{ props.option.vehicle.name }}
											{{ props.option.vehicle.registration_number ? ` • ${props.option.vehicle.registration_number}`: '' }}
											{{ props.option.vehicle.seats ? ` • ${props.option.vehicle.seats} seater`: '' }}
										</small>
									</div>
									<div v-else-if="props.option.driver">
										<small>No vehicle assigned</small>
									</div>
								</div>
							</div>
						</template>
					</VueSelect>
				</div>
				<!--        <div class="form-alert form-alert&#45;&#45;gray" v-if="tripActiveDriver && tripActiveDriver.vehicle">
          <img class="mr-2" src="@/assets/img/gray_bus.svg">
          <p class="mb-0">{{ tripActiveDriver.vehicle.brand }}
            {{ tripActiveDriver.vehicle.name }} • {{ tripActiveDriver.vehicle.registration_number }} •
            {{ tripActiveDriver.vehicle.seats }} Seater</p>
        </div>-->
				<div class="form mb-3" v-if="tripActiveDriver">
					<div class="form-group">
						<label class="form-label">Select Vehicle</label>
						<v-select
							v-model="form.vehicle"
							class="form-group"
							:options="vehicles"
							label="name"
							:filterBy="vehicleFilter"
							required
							placeholder="Select a vehicle to assign"
							>
							<template v-slot:option="vehicle">
								{{ vehicle.brand }} •
								{{ vehicle.name }} {{ vehicle.registration_number }} •
								{{ vehicle.seats }} Seater
							</template>
							<template v-slot:selected-option="vehicle">
								{{ vehicle.brand }} •
								{{ vehicle.name }} {{ vehicle.registration_number }} •
								{{ vehicle.seats }} Seater
							</template>
						</v-select>
					</div>
				</div>
			</template>

		</div>
		<template #modal-footer>
			<div class="w-100">
				<b-button
					:disabled="processing || !buttonEnabled"
					variant="primary"
					class="float-right"
					@click="confirmAction()">
					{{ processing ? 'Processing' : 'Proceed' }}
				</b-button>
				<b-button
					:disabled="processing"
					variant="secondary"
					class="float-right mr-2"
					@click="close()">
					Cancel
				</b-button>
			</div>
		</template>
	</b-modal>

</template>

<script>

import { extractErrorMessage, filterVehicleBy } from '@/utils/helpers'
import VueSelect from 'vue-multiselect'

export default {
  name: 'ChangeWaitlistVehicle',
  components: { VueSelect },
  props: {
    show: Boolean,
    driverId: [Number, String],
    vehicleId: [Number, String],
    itineraryId: [Number, String],
    routeId: [Number, String],
    routeCode: [String],
    itinerary: [String]
  },
  data () {
    return {
      form: {
        vehicle: null,
        password: null
      },
      processing: false,
      errorMessage: '',
      modalId: 'waitlist-vehicle-change-modal',
      steps: 2,
      currentStep: 1,
      promptText: 'Are you sure you want to change the vehicle on this route ?',
      alertMessage: 'It’s important you read the message below!',
      promptKey: 'YES',
      itineraryDrivers: [],
      tripActiveDriver: null,
      vehicles: [],
      isLoading: false
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.showModal()
      } else {
        this.hideModal()
      }
    }
  },
  created () {
    this.fetchVehicles()
  },
  computed: {
    userAvatar () {
      const img = require('@/assets/img/profile.png')
      return img
    },
    vehicleAssigned () {
      return !!this.form.vehicle?.partner_id
    },
    isValidForm () {
      return this.form.vehicle
    },

    validPromptKey () {
      return this.form.password === this.promptKey
    },
    buttonEnabled () {
      return this.isValidForm
    }
  },
  methods: {
    fetchVehicles () {
      this.axios.get('/v1/vehicles').then((res) => {
        this.vehicles = res.data
      })
    },
    vehicleName (param) {
      if (!param) return ''
      return `${param.brand} ${param.name} ${param.type}`
    },
    driverName (param) {
      if (!param) return ''
      return `${param.driver.fname} ${param.driver.lname} • ${this.vehicleName(param.vehicle)}`
    },
    driverDescription (data) {
      if (!data) return ''
      return this.driverName(data)
    },
    assignVehicle () {
      if (!this.isValidForm) {
        return
      }
      this.processing = true
      this.errorMessage = ''
      const vehicleId = this.form.vehicle.id
      this.axios
        .patch(`/v1/route-vehicle/${this.tripActiveDriver.id}`, {
          vehicle_id: this.form.vehicle.id
        })
        .then(() => {
          this.$emit('vehicle-updated', vehicleId)
          this.close()
          this.$swal({
            icon: 'success',
            title: 'Vehicle Updated for Itinerary',
            text: 'Vehicle has been updated successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occurred',
            text: 'An error occurred. please review',
            showCloseButton: true
          })
          const msg = extractErrorMessage(e, 'An error occurred!')
          this.errorMessage = msg
        })
        .finally(() => {
          this.processing = false
          this.form.vehicle = null
        })
    },
    showModal () {
      this.$bvModal.show(this.modalId)
      this.loadModalData()
    },
    close () {
      this.$emit('close')
      this.form.vehicle = ''
    },
    hideModal () {
      this.$bvModal.hide(this.modalId)
    },
    vehicleFilter (option, label, search) {
      return filterVehicleBy(option, label, search)
    },
    confirmAction () {
      if (this.isValidForm) {
        this.assignVehicle()
      }
    },
    loadModalData () {
      this.isLoading = true
      this.axios(`/v1/route-itineraries/${this.itineraryId}/vehicles`).then((res) => {
        this.itineraryDrivers = res.data.data
        /*        this.tripActiveDriver =
          this.itineraryDrivers.find(o => o.driver_id === this.driverId);
        if(!this.tripActiveDriver) {
          throw Error('Unable to match waitlist detail with itinerary vehicle');
        } */
      }).catch((e) => {
        this.$toastr.e(extractErrorMessage(e, e.message || 'Failed to fetch data'))
        this.close()
      }).finally(() => this.isLoading = false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/partials/form-alerts";
@import "src/assets/scss/partials/overrides/v-select";
#waitlist-vehicle-change-modal {
  & .modal-footer {
    border-top: none;
  }
}
</style>
